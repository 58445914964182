import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { Button } from '../../components/Button';
import { FormJoin } from '../../components/FormJoin';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h2" components={components}>{`Text je historicky overená forma zdieľania myšlienok. Ponúkame priestor, kde sa môžeš podeliť o tie svoje.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Priestor na `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/blog"
        }}>{`našom blogu`}</MDXTag>{` je otvorený všetkým, ktorí by radi posúvali kvalitné informácie ďalej.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Najlepšie a najľahšie sa píše o téme, v ktorej sa vyznáš a orientuješ. Ďalšia možnosť, ako o niečom písať je nájsť si oblasť, tému alebo technológiu, ktorú ešte neovládaš, ale máš ju vo svojom todo liste. Môžme tak hovoriť o win-win situácii, kedy sa učíš ty a benefitom je, že sa skrz tvoj článok učia aj ostatní.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Ako sa zapojiť?`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Ak už máš obsah vytvorený, pošli nám ho a keď prejde kontrolou, zariadime, aby sa objavil na našom blogu.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Skontroluj, či článok spĺňa naše `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "/blog/2019-04-17-kriteria-dobreho-clanku-nielen-pre-paralelnu-polis-kosice"
          }}>{`kritéria pre dobrý článok`}</MDXTag>{`.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Ak sa orientuješ aj medzi technológiami, môžeš rovno vytvoriť pull request s tvojím textom v `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/ParalelnaPolisKE/paralelnapoliskosice.sk"
          }}>{`repozitári na GitHub-e`}</MDXTag>{`. Máme tam aj `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/ParalelnaPolisKE/paralelnapoliskosice.sk#writing-a-post"
          }}>{`krátky návod`}</MDXTag>{` ako na to.`}</MDXTag>
      </MDXTag>
      <FormJoin category="Pomoc s obsahom">
  Napíš nám svoju predstavu. Pripoj aj odkaz na tvoj článok. Si z Košíc alebo tu
  vieš dochádzať?
      </FormJoin>
      <MDXTag name="h3" components={components}>{`Chceš písať, no momentálne nevieš o čom?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Niekedy ale správny nápad neprichádza. Nižšie predkladáme zoznam tém, ktoré sa nahromadili medzi našimi poznámkami. Stačí si len vybrať alebo sa inšpirovať:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Bitcoin pre stredne pokročilých`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Bitcoin forky - história, ako fungujú, rozdiely`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Exponenciálna funkcia - demistifikácia logaritmov, rastu, mocnín`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Bitcoin - transakcie - deep dive (p2sh, p2pkh, p2pk, p2sh(p2wsh), p2wsh)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Bitcoin - kryptografia eliptických kriviek`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Bitcoin - segregated wittness`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Bitcoin mining a pooly`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Ako fungujú futures, CFD, forex spot, forex OPCIE, ako sa tvorí cena, čo je volatilita, ako sa dá zarábať na poklese (shorting vs long position)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Internet vecí - raspberryPi, arduino`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Ako funguje smerovanie v Internete (peeringove centrá, BGP)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Techniky memorovania - ako si pamätať dlhé zoznamy`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Dark Web, Dark Net, Tor, I2p`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Ako si zezničiť zdravie pri práci s počítačom`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Lightning Network`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Casper - hardfork ETH, ktorým network prejde z PoW na PoS`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Sharding - potenciálny scaling solution Etherea`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`ZoKrates`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`ICO a regulácie`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Cryptocollectibles - cryptokitties a ďalšie, unique tokens (ERC721)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Decentraland - virtuálny svet na blockchaine, ekonomické možnosti`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Raiden Network / mikro-raiden - off-chain scaling solution, low-fee instantné platby`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Open Zeppelin a Package management pre Smart Contracts`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`DAO - Decentralizovane autonómne spoločnosti`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`DApp development s Web3 a React-om - ako si vytvorit decentralizovanú appku`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Ako si nájst prácu v oblasti Etherea a smart kontraktov - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://colony.io/"
          }}>{`https://colony.io/`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://ethlance.com/"
          }}>{`https://ethlance.com/`}</MDXTag></MDXTag>
      </MDXTag>
      <Button to="/zapoj-sa">Ďalšie možnosti ako sa zapojiť</Button>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      